import { useMemo, useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useParams } from "react-router-dom";
import './css/style.css'

export default function Validacion() {

    const params = useParams();

    const [data, setData] = useState([]);

    const getVehiculo = () => {
        fetch("https://apivehiculos.webforge.cl/validacion/" + params.idqr)
            .then(response => response.json())
            .then(json => setData(json));
    }

    useEffect(() => {
        getVehiculo();
    }, []);

    return (
        <div className="container">
            <div className="centrar">
                <div className="card width mx-auto ">
                    <img src="http://gestionvehicular.prosich.cl/Assets/img/checked.png" className="card-img-top px-5" />
                    <div className="card-body">
                        <h5 className="card-title">Patente: {data?.map(dato => dato.patente)}</h5>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Vehiculo: {data?.map(dato => dato.vehiculo)}</li>
                        <li className="list-group-item">N° Estacionamiento: {data?.map(dato => dato.estacionamiento)}</li>
                        <li className="list-group-item">Block: {data?.map(dato => dato.block)}</li>
                        <li className="list-group-item">Departamento: {data?.map(dato => dato.departamento)}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}