import { useState } from "react";
import Axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2'

export default function Gestor(){
const [vehiculo, setVehiculo] = useState("");
  const [block, setBlock] = useState("");
  const [departamento, setDepartamento] = useState("");
  const [patente, setPatente] = useState("");
  const [estacionamiento, setEstacionamiento] = useState("");
  const [id, setId] = useState("");

  const [editar, setEditar] = useState(false);

  const [vehiculosLista, setvehiculosLista] = useState([]);

  function agregarVehiculo() {
    Axios.post("https://apivehiculos.webforge.cl/create", {
      vehiculo: vehiculo,
      block: block,
      departamento: departamento,
      patente: patente,
      estacionamiento: estacionamiento
    }).then(() => {
      getVehiculos();
      limpiarCampos();

      Swal.fire({
        title: "<strong>Registro exitoso</strong>",
        html: "<i>¡El vehiculo asignado al estacionamiento N°: <strong>" + estacionamiento + "</strong> se ha registrado con exito!</i>",
        icon: 'success',
        timer: 3000
      })
    }).catch(err => console.log(err))
  }

  function actualizarVehiculo() {
    Axios.put("https://apivehiculos.webforge.cl/update", {
      id: id,
      vehiculo: vehiculo,
      block: block,
      departamento: departamento,
      patente: patente,
      estacionamiento: estacionamiento
    }).then(() => {
      getVehiculos();
      alert("Vehiculo registrado");
      limpiarCampos();
      Swal.fire({
        title: "<strong>Actualización exitosa</strong>",
        html: "<i>¡El vehiculo asignado al estacionamiento N°: <strong>" + estacionamiento + "</strong> se ha actualizado con exito!</i>",
        icon: 'success',
        timer: 3000
      })
    }).catch(err => console.log(err))
  }

  function eliminarVehiculo(id) {
    Swal.fire({
      title: '¿Está seguro que desea eliminar el registro?',
      text: "Esta acción no se puede revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        Axios.delete("https://apivehiculos.webforge.cl/delete/" + id, {
        }).then(() => {
          getVehiculos();
          limpiarCampos();
        }).catch(err => console.log(err));
        Swal.fire(
          'Eliminado',
          'El registro se ha eliminado con exito.',
          'success'
        )
      }
    })
  }


  async function getVehiculos() {
    Axios.get("https://apivehiculos.webforge.cl/vehiculos").then((response) => {
      setvehiculosLista(response.data);
    }).catch(err => console.log(err))
  }

  //Esta funcion cambia el boton registrar por los botones por editar y cancelar
  //y pasa los valores a los campos
  function editarVehiculo(val) {
    setEditar(true);

    setVehiculo(val.vehiculo);
    setBlock(val.block);
    setDepartamento(val.departamento);
    setPatente(val.patente);
    setEstacionamiento(val.estacionamiento);
    setId(val.id);
  }

  function limpiarCampos() {
    setEditar(false);
    setVehiculo("");
    setBlock("");
    setDepartamento("");
    setPatente("");
    setEstacionamiento("");
    setId("");
  }


    return (
        <div className='container'>
          <div className="App">
            <div className="card text-center mt-5">
              <div className="card-header">
                Gestion de vehiculos
              </div>
              <div className="card-body">
    
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">Vehiculo</span>
                  <input type="text" value={vehiculo} onChange={(event) => {
                    setVehiculo(event.target.value);
                  }} className="form-control" placeholder="Marca y modelo de vehiculo" aria-label="Vehiculo" aria-describedby="basic-addon1" />
                </div>
    
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">Block</span>
                  <input type="number" value={block} onChange={(event) => {
                    setBlock(event.target.value);
                  }} className="form-control" placeholder="Ingrese block del residente" aria-label="Block" aria-describedby="basic-addon1" />
                </div>
    
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">Departamento</span>
                  <input type="number" value={departamento} onChange={(event) => {
                    setDepartamento(event.target.value);
                  }} className="form-control" placeholder="Ingrese departamento del residente" aria-label="Departamento" aria-describedby="basic-addon1" />
                </div>
    
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">Placa patente</span>
                  <input type="text" value={patente} onChange={(event) => {
                    setPatente(event.target.value);
                  }} className="form-control" placeholder="Ingrese placa patente del vehiculo" aria-label="Placa patente" aria-describedby="basic-addon1" />
                </div>
    
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">Estacionamiento</span>
                  <input type="text" value={estacionamiento} onChange={(event) => {
                    setEstacionamiento(event.target.value);
                  }} className="form-control" placeholder="Ingrese numero de estacionamiento del residente" aria-label="Estacionamiento" aria-describedby="basic-addon1" />
                </div>
    
              </div>
              <div className="card-footer text-muted">
                <div className="row g-0">
                  {
                    editar ?
                      <div className="col-sm-6 col-md-8">
                        <button className='btn btn-warning m-2' onClick={actualizarVehiculo}>Editar</button>
                        <button className='btn btn-info m-2' onClick={limpiarCampos}>Cancelar</button>
                      </div>
                      :
                      <div className="col-sm-6 col-md-8">
                        <button className='btn btn-success m-2' onClick={agregarVehiculo}>Registrar</button>
                      </div>
                  }
                  <div className="col-6 col-md-4">
                    <button className='btn btn-success m-2' onClick={getVehiculos}>Obtener Lista</button>
                  </div>
                </div>
              </div>
            </div>
    
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Vehiculo</th>
                  <th scope="col">Block</th>
                  <th scope="col">Departamento</th>
                  <th scope="col">Placa Patente</th>
                  <th scope="col">Estacionamiento</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {
                  vehiculosLista.map((val, key) => {
                    return (
                      <tr key={val.id}>
                        <th scope="row">{val.id}</th>
                        <td>{val.vehiculo}</td>
                        <td>{val.block}</td>
                        <td>{val.departamento}</td>
                        <td>{val.patente}</td>
                        <td>{val.estacionamiento}</td>
                        <td>
                          <div className="btn-group" role="group" aria-label="Basic example">
                            <button type="button"
                              onClick={() => {
                                editarVehiculo(val)
                              }}
                              className="btn btn-warning">Editar</button>
                            <button type="button" onClick={() => {
                              eliminarVehiculo(val.id)
                            }} className="btn btn-danger">Eliminar</button>
                          </div>
                        </td>
                      </tr>)
                  })
                }
    
              </tbody>
            </table>
          </div>
        </div>
      );
    }